import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: ['"Open Sans"', 'Stratum1Bold', 'Roboto'].join(','),
    },
    palette: {
        action: {
            disabledBackground: 'white',
            disabled: 'white',
        },
    },
});

export default theme;
