import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import jsonData from './DescriptionData.json';
import { IconButton } from '@mui/material';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 450,
        minWidth: '180px',
        backgroundColor: '#E0E0E0',
        color: 'black',
        textAlign: 'left',
        borderRadius: '0px !important',
    },
    link: {
        left: '0px',
    },
    helpIcon: {
        position: 'absolute',
        outline: 'none !important',
        padding: '0px',
    },
}));

const Description = (callback) => {
    const [description, setDescription] = useState({});

    useEffect(() => {
        async function onLoad() {
            setDescription(getDesc(callback.parentCallBack));
        }
        onLoad();
    }, []);

    const showHelp = () => {
        if (!description.isPic) {
            Swal.fire({
                icon: 'info',
                position: 'top-end',
                showCloseButton: true,
                title: 'HELP',
                text: description.help,
            });
        } else {
            Swal.fire({
                icon: 'info',
                width: 1000,
                imageUrl: description.help,
                imageHeight: description.imageHeight,
                imageWidth: description.imageWidth,
                imageAlt: 'loading...',
                showCloseButton: true,
            });
        }
    };

    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <div style={{ float: 'left', width: '88%' }}>
                                <Typography gutterBottom variant="body1">
                                    {description.description}
                                </Typography>
                            </div>
                            <div style={{ float: 'right' }}>
                                <IconButton
                                    onClick={() => showHelp()}
                                    className={classes.helpIcon}
                                    size="large"
                                >
                                    <HelpIcon />
                                </IconButton>
                            </div>
                            <Typography noWrap={true} variant="inherit">
                                {description.link == null ? null : (
                                    <a
                                        href={description.link}
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                    >
                                        Link to STORM
                                    </a>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

function getDesc(name) {
    return jsonData.find((item) => {
        return item.name === name.name;
    });
}

export default Description;
