import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LookupService from 'services/lookups.service';
import { IPromotionLimitResponse } from 'types/promotions.type';
import { ILookupResponse } from 'types/lookups.type';

import { useQuery } from '@tanstack/react-query';
import './promotions.css';
import TextField from '@mui/material/TextField';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { blue } from '@mui/material/colors';
import {
    FormLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
} from '@mui/material';
import { useMsal } from '@azure/msal-react';

interface PromotionLimitsProps {
    initialState: PromotionLimitsState | IPromotionLimitResponse;
    onChange(state: PromotionLimitsState);
    readOnly?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface PromotionLimitsState {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
    limitOncePerEmail: boolean;
    singleUsage: boolean;
    selectedMarkets: string[];
}

function isState(
    state: PromotionLimitsState | IPromotionLimitResponse,
): state is PromotionLimitsState {
    return (state as PromotionLimitsState)?.singleUsage !== undefined;
}
function isResponse(
    state: PromotionLimitsState | IPromotionLimitResponse,
): state is IPromotionLimitResponse {
    return (state as IPromotionLimitResponse)?.limitPerCode !== undefined;
}

const PromotionLimits: FC<PromotionLimitsProps> = ({
    initialState,
    readOnly = false,
    onChange = () => {},
}) => {
    const { instance, accounts } = useMsal();
    const lookupService = new LookupService(instance);

    const { data: markets } = useQuery<ILookupResponse[]>(
        ['markets'],
        async () => {
            const { data } = await lookupService.listMarkets();
            return data;
        },
        {
            keepPreviousData: true,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        },
    );

    const [state, setState] = useState<PromotionLimitsState>({
        startDate: dayjs(Date()),
        endDate: dayjs(Date()),
        limitOncePerEmail: false,
        selectedMarkets: [],
        singleUsage: false,
    });

    useEffect(() => {
        if (isState(initialState)) {
            setState(initialState);
        } else if (isResponse(initialState)) {
            setState({
                startDate: dayjs(initialState.startDate),
                endDate: dayjs(initialState.endDate),
                limitOncePerEmail: initialState.limitPerEmail == 1,
                selectedMarkets: initialState.limitMarkets,
                singleUsage: initialState.limitPerCode == 1,
            });
        }
    }, [initialState]);

    const handleStateChange = (newState) => {
        newState = { ...state, ...newState };
        setState(newState);
        onChange(newState);
    };

    const handleMarketChange = (event) => {
        const {
            target: { value },
        } = event;
        handleStateChange({
            ...state,
            selectedMarkets:
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
        });
    };

    return (
        <Stack spacing={3} sx={{ padding: 0 }}>
            <Box
                sx={{
                    width: '51ch',
                    borderRadius: 1,
                    border: 1,
                    borderColor: '#dee2e6',
                    paddingLeft: 1,
                    paddingRight: 1,
                }}
            >
                <FormLabel
                    style={{
                        marginLeft: '0.71em',
                        marginTop: '-0.71em',
                        paddingLeft: '0.44em',
                        zIndex: 2,
                        width: '4.2em',
                        position: 'absolute',
                        fontSize: '0.75em',
                        backgroundColor: '#FFF',
                    }}
                >
                    Limits
                </FormLabel>
                <Stack direction="row" spacing={1} sx={{ paddingTop: 3 }}>
                    <FormControl sx={{ width: '25ch' }}>
                        <DesktopDateTimePicker
                            label="Start Date"
                            value={state.startDate}
                            onChange={(x) =>
                                handleStateChange({ startDate: x })
                            }
                            slotProps={{ textField: {} }}
                            shouldDisableDate={(date: dayjs.Dayjs) =>
                                !date.isSame(state.endDate, 'day') &&
                                !date.isBefore(state.endDate, 'day')
                            }
                            ampm={false}
                            views={['year', 'day', 'hours', 'minutes']}
                            readOnly={readOnly}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '25ch' }}>
                        <DesktopDateTimePicker
                            label="End Date"
                            value={state.endDate}
                            onChange={(x) => handleStateChange({ endDate: x })}
                            slotProps={{ textField: {} }}
                            shouldDisableTime={(time, d) =>
                                state.startDate.get(d) > time.get(d) &&
                                state.endDate.isSame(state.startDate, 'day')
                            }
                            shouldDisableDate={(date: dayjs.Dayjs) =>
                                !date.isSame(state.startDate, 'day') &&
                                !date.isAfter(state.startDate, 'day')
                            }
                            ampm={false}
                            views={['year', 'day', 'hours', 'minutes']}
                            readOnly={readOnly}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing={3} sx={{ padding: 1 }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    color="success"
                                    checked={state.limitOncePerEmail as any}
                                    onChange={(event) =>
                                        handleStateChange({
                                            limitOncePerEmail:
                                                event.target.checked,
                                        })
                                    }
                                    sx={{
                                        paddingLeft: 1,
                                    }}
                                />
                            }
                            label="Limit once per email"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    color="success"
                                    checked={state.singleUsage as any}
                                    onChange={(event) =>
                                        handleStateChange({
                                            singleUsage: event.target.checked,
                                        })
                                    }
                                    sx={{
                                        paddingLeft: 1,
                                    }}
                                />
                            }
                            label="Single usage"
                        />
                    </FormGroup>
                    <FormControl sx={{ width: '24ch' }}>
                        <InputLabel id="market-select-label">
                            Markets
                        </InputLabel>
                        <Select
                            labelId="market-select-label"
                            id="market-select"
                            value={state.selectedMarkets}
                            label="Markets"
                            onChange={handleMarketChange}
                            multiple
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            readOnly={readOnly}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {markets?.map((market) => (
                                <MenuItem key={market.id} value={market.id}>
                                    <Checkbox
                                        checked={
                                            state.selectedMarkets?.indexOf(
                                                market.id,
                                            ) > -1
                                        }
                                        sx={{
                                            color: blue[700],
                                            '&.Mui-checked': {
                                                color: blue[700],
                                            },
                                        }}
                                    />
                                    <ListItemText
                                        primary={
                                            '[' + market.id + '] ' + market.name
                                        }
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
        </Stack>
    );
};

export default PromotionLimits;
