import { ILookupResponse } from '../types/lookups.type';
import { Service } from './Service';
import { LifeCycle, StatusFlag } from '../types/products.type';

export default class LookupService extends Service {
    listMarkets() {
        return this.client.get<Array<ILookupResponse>>('lookup/markets');
    }
    listLanguages(marketId?: string) {
        let url = 'lookup/languages?';
        if (marketId) {
            url = url + 'marketId=' + marketId;
        }
        return this.client.get<Array<ILookupResponse>>(url);
    }
    listEmailLists() {
        return this.client.get<Array<ILookupResponse>>('lookup/emailLists');
    }
    listPromotions() {
        return this.client.get<Array<ILookupResponse>>('lookup/promotions');
    }

    listLifeCycles(): Array<ILookupResponse> {
        return this.ToLookup(LifeCycle, true);
    }

    listStatuses(): Array<ILookupResponse> {
        return this.ToLookup(StatusFlag);
    }

    listExportFormats() {
        return this.client.get<Array<ILookupResponse>>('lookup/exportformats');
    }

    listPriceLists(marketId?: string, companyId?: number) {
        let url = 'lookup/priceLists?';

        if (marketId) {
            url = url + 'marketId=' + marketId;
        }
        if (companyId) {
            url = url + '&companyId=' + companyId;
        }
        return this.client.get<Array<ILookupResponse>>(url);
    }

    listCompanies(marketId: string) {
        return this.client.get<Array<ILookupResponse>>(
            `lookup/companies?marketId=${marketId}`,
        );
    }

    private ToLookup<T, K extends keyof T>(
        enumObject: T,
        excludeFirstItem = false,
    ) {
        const response = new Array<ILookupResponse>();
        const keys = Object.keys(enumObject as object).filter((v) =>
            isNaN(Number(v)),
        );

        const values = Object.values(enumObject as object);

        for (const key in keys) {
            if (excludeFirstItem && Number(key) == 0) continue;
            response.push({ id: keys[key], name: values[key] as string });
        }

        return response;
    }
}
