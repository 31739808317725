import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Swal } from 'sweetalert2';
import { withMsal } from '@azure/msal-react';
import menuItems from '../Items.json';
import SideNav from '../navigation/SideNav';
import APIFetcher from '../remoteData/APIFetcher';
import Description from '../helpcomponents/Descriptions/Description';
import { BlkMaterialTable } from '../helpcomponents/Tables/MaterialTable';
import { BlkCircularProgress } from '../helpcomponents/Progress/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import {
    Select,
    Button,
    TextareaAutosize,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';

const useStyles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    textArea: {
        minWidth: '30%',
        resize: 'both',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 450,
        backgroundColor: '#E0E0E0',
        color: 'black',
        borderRaduius: '25px',
    },
    responsePaper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 768,
        backgroundColor: 'whitesmoke',
        color: 'black',
        borderStyle: 'solid',
    },
    buttonStyle: {
        backgroundColor: '#0d1523',
        marginTop: '3%',
        marginBottom: '3%',
        color: 'white',
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
});

class ImportUsersByEmailComponent extends Component {
    static displayName = ImportUsersByEmailComponent.name.toUpperCase();
    constructor(props) {
        super(props);
        this.state = {
            market: 'SE',
            drawerOpen: true,
            textArea: '',
            tableData: [],
            responseData: {},
            dataPosted: false,
            loading: false,
            descriptionAndHelpHandler: {
                name: 'ImportUsersByEmail',
                func: this.setGetHelp,
                helpDiv: true,
            },
        };
        this.api = new APIFetcher(props.msalContext.instance);
    }

    initReactGA = () => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('/laundry/importusersbyemail');
    };
    componentDidMount() {
        this.initReactGA();
    }

    shiftSideNav = (changeStatus) => {
        this.setState({ drawerOpen: changeStatus });
    };

    checkTextArea = () => {
        if (this.state.textArea === '') {
            return Swal.fire({
                icon: 'error',
                showCloseButton: true,
                title: 'Error',
                text: 'Enter value in the textarea..',
            });
        }

        let textAreaStrings = this.textArea.value.split(/[ ,\n]+/);
        textAreaStrings = textAreaStrings.filter(function (e) {
            return e;
        });

        const emailArray = {
            Customers: textAreaStrings.map((email) => ({
                Email: email,
            })),
        };

        const result = emailArray.Customers.find(
            (email) => !email.Email.includes('@') || !email.Email.includes('.'),
        );
        if (result === undefined) {
            this.postEmails(emailArray);
        } else {
            Swal.fire({
                icon: 'error',
                showCloseButton: true,
                title: 'Error',
                text: `${result.Email} is not a correct emailadress`,
            });
        }
    };

    async postEmails(emailArray) {
        this.setState({ loading: true });

        const response = await this.api.postEmails(
            this.state.market,
            emailArray,
        );
        if (response !== '500' && response !== 500 && response !== null) {
            this.setState({
                responseData: response,
                dataPosted: true,
                loading: false,
            });
        } else {
            this.setState({ dataPosted: false, loading: false });
            Swal.fire({
                icon: 'error',
                showCloseButton: true,
                title: 'error',
                text: 'Something went wrong.. Contact Admin if this occurs again.',
            });
        }
    }

    displayResponse = () => {
        const values = Object.values(this.state.responseData);
        const columns = [
            { title: 'Email', field: 'Email' },
            { title: 'CustomerId', field: 'CustomerId' },
            { title: 'Status', field: 'Status' },
        ];
        const tableData = { columns: columns, data: values, title: 'Added' };
        return <BlkMaterialTable {...tableData} />;
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    displayTextArea = () => {
        const { classes } = this.props;

        return (
            <TextareaAutosize
                ref={(textarea) => (this.textArea = textarea)}
                value={this.state.textArea}
                minRows={3}
                className={classes.textArea}
                name="textArea"
                onChange={this.onChange}
            />
        );
    };

    displayButton = () => {
        const { classes } = this.props;
        return (
            <Button
                className={classes.buttonStyle}
                onClick={this.checkTextArea}
            >
                Import
            </Button>
        );
    };

    newImportButton = () => {
        const { classes } = this.props;
        return (
            <Button
                className={classes.buttonStyle}
                onClick={() => {
                    this.setState({
                        tableData: [],
                        responseData: {},
                        dataPosted: false,
                        loading: false,
                    });
                }}
            >
                New Import
            </Button>
        );
    };

    dropDown = () => {
        return (
            <FormControl sx={{ paddingTop: 1 }}>
                <InputLabel id="market-select-label">Market</InputLabel>
                <Select
                    onChange={(e) => this.setState({ market: e.target.value })}
                    value={this.state.market}
                >
                    <MenuItem value={'SE'}>SE</MenuItem>
                    <MenuItem value={'NO'}>NO</MenuItem>
                    <MenuItem value={'FI'}>FI</MenuItem>
                </Select>
                <br />
            </FormControl>
        );
    };

    render() {
        const contentStyle = {
            textAlign: 'center',
            margin: '0 auto',
            transition: 'margin-left 1000ms cubic-bezier(0.23, 1, 0.32, 1)',
        };
        const responseStyle = { justifyContent: 'center', marginTop: '3%' };
        const textAreaStyle = { textAlign: 'center', marginTop: '1%' };

        const storedGroups = JSON.parse(localStorage.getItem('groups'));
        const TextArea = this.displayTextArea;
        const Response = this.displayResponse;
        const DisplayButton = this.displayButton;
        const NewImportButton = this.newImportButton;
        const DropDown = this.dropDown;

        if (storedGroups[3].isMember) {
            //[0] == Admin, [1] == Export, [2] == Import, [3] == Laundry
            if (this.state.drawerOpen) {
                contentStyle.marginLeft = 256;
            }
            return (
                <div id="parentDiv" style={{ zIndex: '97' }}>
                    <SideNav
                        items={menuItems.Laundry}
                        callback={this.shiftSideNav}
                    />

                    <div id="contentDiv" style={contentStyle}>
                        <div id="instructionDiv" style={responseStyle}>
                            <Description
                                parentCallBack={
                                    this.state.descriptionAndHelpHandler
                                }
                            />
                        </div>
                        <div
                            id="dropdownDiv"
                            style={{ textAlign: 'center', marginTop: '30px' }}
                        >
                            <DropDown />
                        </div>
                        <div
                            style={textAreaStyle}
                            id="textAreaDiv"
                            hidden={this.state.dataPosted}
                        >
                            Enter emails:
                            <br />
                            <TextArea />
                        </div>
                        <div
                            style={{ textAlign: 'right', marginRight: '35%' }}
                            hidden={this.state.dataPosted}
                        >
                            <DisplayButton />
                        </div>
                        <div
                            id="progressDiv"
                            style={{ marginTop: '3%', marginBottom: '2%' }}
                            hidden={!this.state.loading}
                        >
                            <BlkCircularProgress />
                        </div>
                        <div
                            style={{ marginBottom: '5%' }}
                            hidden={!this.state.dataPosted}
                        >
                            {this.state.dataPosted ? <Response /> : <div />}
                            <NewImportButton />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>
                        YOU DO NOT HAVE ACCESS TO VIEW THE LAUNDRY PAGE. PLEASE
                        CONTACT YOUR ADMINISTRATOR TO GAIN ACCESS
                    </h1>
                </div>
            );
        }
    }
}
export default withMsal(withStyles(useStyles)(ImportUsersByEmailComponent));
