import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MaterialTable from '@material-table/core';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuItem, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    table: {
        marginRight: '20px',
        marginTop: '20px',
        marginLeft: '20px',
        minWidth: '20vw',
    },
}));

// Example for props if you want to add a dropdown next to search in the table tableType:{ tableType: "dropDown", dropDownItems : [ "all", "Ok", "Not processed or timed out", "Locker not found", "Locker already occupied" ]}

export const BlkMaterialTable = (data) => {
    const [dropDownFilter, setDropDownFilter] = useState('all');
    const classes = useStyles();

    const getDropdown = (items) => {
        //<TextField select label="Filter status" defaultValue={"all"} value={this.state.filterOption} name="filterOption" onChange={(e) => this.setTableData(e.target.value)} SelectProps={{ classes: { icon: classes.icon }, native: false }} InputProps={{ className: classes.inputInTable, disableUnderline: true, }} InputLabelProps={{ className: classes.inputBorder }} variant="filled">
        return (
            <TextField
                select
                label="Filter status"
                defaultValue={'all'}
                name="filterOption"
                onChange={(e) => setDropDownFilter(e.target.value)}
                variant="filled"
            >
                {items.type.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    const passBackToParent = (rowData) => {
        data.parentCallback(rowData);
    };

    // Example for props if you want to add a dropdown next to search in the table tableType:{ tableType: "dropDown", dropDownItems : [ "all", "Ok", "Not processed or timed out", "Locker not found", "Locker already occupied" ]}
    const ActionIcon = getIcon;
    const FilterDropDown = getDropdown;
    return (
        <div className={classes.table}>
            <MaterialTable
                columns={data.columns}
                data={
                    dropDownFilter === 'all'
                        ? data.data
                        : data.data.filter(
                              (items) => items.status === dropDownFilter,
                          )
                }
                title={
                    <div>
                        <h5>{data.title}</h5>
                    </div>
                }
                icons={{
                    Filter: React.forwardRef((props, ref) => ''),
                    Clear: (props) => <ClearIcon />,
                    Search: React.forwardRef((props, ref) => (
                        <SearchIcon ref={ref}></SearchIcon>
                    )),
                    ResetSearch: (props) => <ClearIcon />,
                }}
                options={{
                    pageSize:
                        data.pageSizeOptions !== undefined
                            ? data.pageSizeOptions[
                                  data.pageSizeOptions.length - 1
                              ]
                            : 15,
                    pageSizeOptions:
                        data.pageSizeOptions !== undefined
                            ? data.pageSizeOptions
                            : [0, 15, 30, 50, 100, 200, 1000],
                    headerStyle: {
                        backgroundColor: '#0d1523',
                        color: 'white',
                        position: 'sticky',
                        top: 0,
                    },
                    minBodyHeight:
                        data.bodyHeight !== undefined
                            ? data.bodyHeight
                            : undefined,
                    maxBodyHeight:
                        data.bodyHeight !== undefined
                            ? data.bodyHeight
                            : undefined,
                    filtering: data.filter !== undefined ? true : false,
                    actionsColumnIndex: -1,
                    rowStyle: (rowData, index) => ({
                        backgroundColor: index % 2 ? '#f6f6f6' : '',
                    }),
                }}
                actions={[
                    {
                        icon: () =>
                            data.tableType !== undefined ? (
                                data.tableType.tableType === 'IconButton' ? (
                                    <ActionIcon
                                        type={data.tableType.buttonIcon}
                                    />
                                ) : data.tableType.tableType === 'dropDown' ? (
                                    <FilterDropDown
                                        type={data.tableType.dropDownItems}
                                    />
                                ) : (
                                    <div />
                                )
                            ) : (
                                ''
                            ), //for the add table use PersonAddIcon here.. For the edittable use EditIcon here..
                        tooltip:
                            data.tableType !== undefined
                                ? getToolTipForIconButton(
                                      data.tableType.buttonIcon,
                                  )
                                : '',
                        onClick: (event, rowData) =>
                            data.tableType.tableType !== 'dropDown'
                                ? passBackToParent(rowData)
                                : null,
                        disabled: data.tableType === undefined,
                        isFreeAction:
                            data.tableType !== undefined
                                ? data.tableType.tableType === 'dropDown'
                                    ? true
                                    : false
                                : false,
                    },
                ]}
                localization={{
                    header: {
                        actions:
                            data.tableType !== undefined
                                ? getToolTipForIconButton(
                                      data.tableType.buttonIcon,
                                  )
                                : '',
                    },
                }}
            />
        </div>
    );
};

const getToolTipForIconButton = (tooltip) => {
    if (tooltip === 'Add') {
        return 'Add';
    } else if (tooltip === 'Edit') {
        return 'Edit';
    } else if (tooltip === 'Remove') {
        return 'Remove';
    } else return '';
};

const getIcon = (type) => {
    return type.type === 'Add' ? (
        <PersonAddIcon />
    ) : type.type === 'Edit' ? (
        <EditIcon />
    ) : type.type === 'Remove' ? (
        <DeleteIcon />
    ) : (
        <div></div>
    );
};
