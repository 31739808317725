import React, { Component } from 'react';
import ReactGA from 'react-ga';
import menuItems from '../Items.json';
import SideNav from '../navigation/SideNav';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Description from '../helpcomponents/Descriptions/Description';
import Swal from 'sweetalert2';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import GetAppIcon from '@mui/icons-material/GetApp';
import MenuItem from '@mui/material/MenuItem';
import { BlkCircularProgress } from '../helpcomponents/Progress/CircularProgress';
import { withMsal } from '@azure/msal-react';
import APIFetcher from '../remoteData/APIFetcher';

const useStyles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    inputFormStyle: {
        minWidth: '40vw',
        maxWidth: '50%',
        borderRadius: 15,
        padding: '30px',
        backgroundColor: '#E0E0E0',
        boxSizing: 'border-box',
        color: 'black',
        ['@media screen and (max-width: 900px)']: {
            maxWidth: '100% !important',
            display: 'block',
        },
    },
    formDiv: {
        display: 'flex',
        flexDirection: 'column', //column for xs screen size
        marginLeft: '25px',
        marginRight: '25px',
        alignItems: 'center',
        minWidth: '60%',
        ['@media screen and (max-width: 900px)']: {
            display: 'block',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        color: 'black',
        width: '70%',
        borderRadius: '10px',
        backgroundColor: '#E0E0E0',
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            //border to TextField in form
            borderColor: 'black',
        },
    },
    input: {
        color: 'black', //fontcolor for labels and selects
        fontSize: 20,
        width: '100%',
        borderRadius: '25px',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    inputBorder: {
        color: 'grey !important', //fontcolor to placeholder in form
        fontSize: 14,
        borderRadius: 25,
    },
    icon: {
        color: 'black',
    },
    formTitle: {
        marginBottom: '1%',
        fontSize: '1.5rem',
    },
    uploadButton: {
        backgroundColor: '#0d1523',
        color: 'white',
        fontSize: '0.9rem',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        lineHeight: '11px',
        textAlign: 'center',
        '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed !important',
            pointerEvents: 'fill',
            color: 'white',
            '&:hover': {
                backgroundColor: '#0d1523',
                color: 'white',
            },
        },
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    submitButton: {
        backgroundColor: '#0d1523',
        color: 'white',
        fontSize: '0.9rem',
        lineHeight: '11px',
        height: '40px',
        marginTop: '4%',
        marginBottom: '1%',
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    hiddenPFileName: {
        color: 'black',
        marginBottom: '2%',
        fontSize: '0.9rem',
    },
});

const companyOptionsList = require('./companyOptionsList.json');

class CreateStormImportfile extends Component {
    static displayName = CreateStormImportfile.name;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            CompanyId: '',
            companyListOptions: [],
            faulty: false,
            excelFile: [],
            btnDisabled: true,
            drawerOpen: true,
            items: [],
            fileName: '',
            responseData: {},
            fileUploaded: false,
            filePosted: false,
            descriptionAndHelpHandler: { name: 'CreateStormImportfile' },
        };
        this.api = new APIFetcher(props.msalContext.instance);
    }

    shiftSideNav = (changeStatus) => {
        // hide/show sidenav.
        this.setState({ drawerOpen: changeStatus });
    };

    initReactGA = () => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('/import/createstormimportfile');
    };

    async componentDidMount() {
        this.initReactGA();
        let companyOptions = companyOptionsList.map((item) => ({
            key: item.id,
            label: item.name,
            value: item.name,
            show: item.show,
        }));
        companyOptions = companyOptions.filter((item) => item.show === '1');
        this.setState({ drawerOpen: true, companyListOptions: companyOptions });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    createStormImportfile = (e) => {
        this.setState({ loading: true });
        e.preventDefault();
        const file = new FormData();
        file.append(this.state.CompanyId, this.state.excelFile);
        this.downloadStormImportFile(file);
    };

    downloadStormImportFile(file) {
        const uri = 'Laundry/CreateStormImportfile';
        this.api
            .downloadStormImportFile(file, uri)
            .then(() => this.setState({ loading: false }));
    }

    convertForm = () => {
        const { classes } = this.props;
        const UploadButton = this.uploadButton;
        return (
            <div className={classes.formDiv}>
                <form
                    className={classes.inputFormStyle}
                    onSubmit={this.createStormImportfile}
                    autoComplete="off"
                >
                    <Typography className={classes.formTitle}>
                        Create Storm Importfile
                    </Typography>
                    <Grid
                        style={{ marginBottom: '5%' }}
                        container
                        justifyContent="center"
                    >
                        <FormControl
                            className={classes.formControl}
                            required
                            fullWidth
                        >
                            <TextField
                                select
                                label="CompanyId"
                                value={this.state.CompanyId}
                                name="CompanyId"
                                onChange={this.onChange}
                                SelectProps={{
                                    classes: { icon: classes.icon },
                                    native: false,
                                }}
                                InputProps={{
                                    className: classes.input,
                                    disableUnderline: true,
                                }}
                                InputLabelProps={{
                                    className: classes.inputBorder,
                                }}
                                variant="filled"
                                required
                            >
                                {this.state.companyListOptions.map((option) => (
                                    <MenuItem
                                        key={option.key}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <FormControl className={classes.formControl} required>
                            <UploadButton />
                        </FormControl>
                    </Grid>

                    <p
                        className={classes.hiddenPFileName}
                        hidden={this.state.btnDisabled}
                    >
                        Filename: {this.state.fileName}
                    </p>
                    <Button
                        disabled={this.state.loading}
                        hidden={this.state.btnDisabled}
                        type="submit"
                        variant="contained"
                        className={classes.submitButton}
                        endIcon={<GetAppIcon />}
                        color="primary"
                    >
                        GET FILE
                    </Button>
                </form>
            </div>
        );
    };

    customDesc = () => {
        return (
            <Description
                parentCallBack={this.state.descriptionAndHelpHandler}
            />
        );
    };

    isValid = (str) => {
        if (typeof str !== 'string') {
            return false;
        }
        for (let i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 127) {
                return false;
            }
        }
        return true;
    };

    uploadButton = () => {
        const { classes } = this.props;
        return (
            <Button
                className={classes.uploadButton}
                variant="contained"
                component="label"
            >
                Upload File
                <input
                    type="file"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        if (!file) {
                            //Working
                        } else {
                            //var checkName = this.isValid(file.name)
                            const checkExcel = file.name.endsWith('.xlsx');
                            if (checkExcel) {
                                this.setState({
                                    btnDisabled: false,
                                    fileName: file.name,
                                    excelFile: file,
                                    fileUploaded: false,
                                    filePosted: false,
                                    faulty: false,
                                });
                            } else if (!checkExcel) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Only XLSX files allowed!',
                                    footer: '<p>Check the instructions for more information</p>',
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Unexpected error..',
                                    footer: '<p>Please contact administrator</p>',
                                });
                            }
                        }
                    }}
                    hidden
                />
            </Button>
        );
    };

    render() {
        const Desc = this.customDesc;
        const ConvertForm = this.convertForm;
        const contentStyle = {
            textAlign: 'center',
            transition: 'margin-left 1000ms cubic-bezier(0.23, 1, 0.32, 1)',
        };
        const storedGroups = JSON.parse(localStorage.getItem('groups'));
        if (storedGroups[2].isMember) {
            // [0] == Admin, [1] == Export, [2] == Import, [3] == Laundry //
            if (this.state.drawerOpen) {
                contentStyle.marginLeft = 256;
            }
            return (
                <div id="returnParent" style={{ zIndex: '97' }}>
                    <SideNav
                        items={menuItems.Sales}
                        callback={this.shiftSideNav}
                    />
                    <div id="content" style={contentStyle}>
                        <br />
                        <br />
                        <Desc />
                        <br />
                        <ConvertForm />
                        <br />
                        <br />
                        <div
                            id="progressDiv"
                            style={{ marginTop: '3%', marginBottom: '3%' }}
                            hidden={!this.state.loading}
                        >
                            <BlkCircularProgress />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>
                        YOU DO NOT HAVE ACCESS TO VIEW THE IMPORT PAGE. PLEASE
                        CONTACT YOUR ADMINISTRATOR TO GAIN ACCESS
                    </h1>
                </div>
            );
        }
    }
}

export default withMsal(withStyles(useStyles)(CreateStormImportfile));
