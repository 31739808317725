import React, { FC, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Drawer, ListItem, List, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
//import APIFetcher from "../remoteData/APIFetcher";
import { useMsal } from '@azure/msal-react';
import { v4 as uuidv4 } from 'uuid';

const drawerWidth = 240;

interface BlkMenuItem {
    name: string;
    items: BlkMenuSubItem[];
}

interface BlkMenuSubItem {
    name: string;
    url: string;
    items: BlkMenuSubItem[];
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawerPaper: {
        position: 'fixed',
        zIndex: 998,
        height: '100%',
        width: drawerWidth,
        backgroundColor: '#121e31',
        /* ['@media (min-width:1200px)']: {
          width: drawerWidth,   
        }, */
        ['@media (max-width:780px)']: {
            width: drawerWidth, //Change sidenav width for mobile here... <------
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    hide: {
        display: 'none',
        color: 'white',
    },
    menuButton: {
        //color: "black",
        position: 'fixed',
        '&:hover': {
            backgroundColor: 'white',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    drawerHeader: {
        marginTop: '7%',
        fontWeight: '800',
        fontSize: '1.5rem',
        height: '80px',
    },
    listItemTextHeader: {
        opacity: '0.95',
        color: 'white',
        fontWeight: '800',
        fontSize: '1.5rem',
        marginLeft: '4%',
    },
    iconArrow: {
        color: 'white',
        border: 0,
        backgroundColor: '#0d1523',
        //fontColor: 'white',
        //marginBottom: '5%',

        // top: '-25px',
        // left: '10px',
        opacity: '0.9',
        '&:focus': {
            outline: 'none',
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    childExpand: {
        color: 'white',
        '&:hover': {
            outline: 'none',
            backgroundColor: '#1d2f4e',
            cursor: 'pointer',
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: '#1d2f4e',
        },
        '&.active': {
            outline: 'none',
            color: '#c31f39',
            backgroundColor: '#1d2f4e',
        },
    },
    childList: {
        marginLeft: '1vw',
    },
    parentButton: {
        width: '80%',
        // justifyContent: 'left',
        // paddingLeft: "15px",
        //paddingRight: "15px",
        //marginLeft: "38px",
        color: 'white',
        border: '1px solid #2c3b56',
        backgroundColor: '#131e31',
        '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
            pointerEvents: 'fill',
            color: 'white !important',
            backgroundColor: '#0d1523 !important',
            '&:hover': {
                opacity: 0.5,
            },
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: '#0d1523',
            color: 'white',
        },
        '&:hover': {
            backgroundColor: '#0d1523',
            opacity: 0.8,
            color: 'white',
        },
        '&.active': {
            backgroundColor: '#ffe002',
            color: 'black',
        },
    },
    childButton: {
        width: 'auto',
        color: 'black',
        outline: 'none !important',
        backgroundColor: '#E0E0E0 !important',
    },
}));

interface SideNavProps {
    callback?: (any) => void;
    items: BlkMenuItem;
}

const SideNav: FC<SideNavProps> = (props: {
    items: BlkMenuItem;
    callback?: any;
}) => {
    const { instance, accounts, inProgress } = useMsal();
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const classes = useStyles();
    const { items, callback } = props;

    const handleDrawerOpen = () => {
        //Opens Sidenav
        setDrawerOpen(true);
        if (callback) callback(true);
    };

    const handleDrawerClose = () => {
        //Closes Sidenav
        setDrawerOpen(false);
        if (callback) callback(false);
    };

    const NoChildrenNoParent = (subOption) => {
        return (
            <ListItem key={uuidv4()}>
                <Button
                    disableFocusRipple
                    disableRipple
                    className={classes.parentButton}
                    component={NavLink}
                    to={subOption.url}
                >
                    {subOption.name}
                </Button>
            </ListItem>
        );
    };

    const ParentWithChildren = (subOption: BlkMenuSubItem) => {
        const [open, setOpen] = React.useState(false);
        const handleClick = () => {
            //OnClick parentOption
            setOpen((prev) => !prev);
        };

        return (
            <React.Fragment key={uuidv4()}>
                <ListItem>
                    <Button
                        disableFocusRipple
                        disableRipple
                        className={classes.parentButton}
                        component={NavLink}
                        to={subOption.url}
                    >
                        {subOption.name}
                    </Button>
                    <ListItemText
                        className={classes.childExpand}
                        onClick={handleClick}
                        primary={open ? <ExpandLess /> : <ExpandMore />}
                    />
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List
                        className={classes.childList}
                        component="div"
                        disablePadding
                    >
                        {subOption.items.map((child) => (
                            <Button
                                disableFocusRipple
                                disableRipple
                                className={classes.parentButton}
                                component={NavLink}
                                to={child.url}
                                key={uuidv4()}
                            >
                                {child.name}
                            </Button>
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        );
    };

    return (
        <div id="SideNavParent" className={classes.root}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, drawerOpen && classes.hide)}
                size="large"
            >
                <ChevronRightIcon />
            </IconButton>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <ListItem className={classes.listItemTextHeader}>
                        <ListItemText className={classes.listItemTextHeader}>
                            <h1>{items.name}</h1>
                        </ListItemText>
                        <IconButton
                            className={classes.iconArrow}
                            onClick={handleDrawerClose}
                            size="large"
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: 3,
                            }}
                        >
                            {theme.direction === 'ltr' ? (
                                <ChevronLeftIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    </ListItem>
                </div>

                <Divider />
                {items.items.map((options) =>
                    options.items === undefined
                        ? NoChildrenNoParent(options)
                        : ParentWithChildren(options),
                )}
            </Drawer>
        </div>
    );
};

export default SideNav;
