import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { MenuItem, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    inputBorder: {
        color: 'grey !important', //fontcolor to placeholder in form
        fontSize: 14,
        borderRadius: 25,
    },
    textField: {
        backgroundColor: 'white !important',
        '&$focused $notchedOutline': {
            borderColor: 'black',
            color: 'black',
        },
    },
    focused: {
        color: 'black',
    },
}));

export const BoolDropdown = (field) => {
    const classes = useStyles();
    const [bool, setBool] = useState();
    const [dropdownValue, setDropdownValue] = useState('');
    const [defaultValueTrue, setDefaultValueTrue] = useState('Yes');

    const handleSetValue = (e) => {
        console.log(e);
        if (e === 'Yes') {
            setDropdownValue('Yes');
            setBool(true);
        } else {
            setDropdownValue('No');
            setBool(false);
        }
    };
    const handleSetDefaultValueTrue = (e) => {
        console.log(e);
        if (e === 'Yes') {
            setDefaultValueTrue('Yes');
            setBool(true);
        } else {
            setDefaultValueTrue('No');
            setBool(false);
        }
    };

    if (field.deafultValue === 'true') {
        return (
            <TextField
                select={true}
                value={defaultValueTrue}
                defaultValue={defaultValueTrue}
                onChange={(e) => handleSetDefaultValueTrue(e.target.value)}
                hidden={field.hidden}
                key={field.name}
                label={field.name}
                name={field.name}
                variant="filled"
                InputProps={{
                    classes: {
                        root: classes.textField,
                        focused: classes.focused,
                    },
                }}
                InputLabelProps={{ className: classes.inputBorder }}
                SelectProps={{ renderValue: (defaultValue) => defaultValue }}
            >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
            </TextField>
        );
    } else {
        return (
            <TextField
                select={true}
                defaultValue={''}
                onChange={(e) => handleSetValue(e.target.value)}
                value={dropdownValue}
                hidden={field.hidden}
                key={field.name}
                label={field.name}
                name={field.name}
                variant="filled"
                InputProps={{
                    classes: {
                        root: classes.textField,
                        focused: classes.focused,
                    },
                }}
                InputLabelProps={{ className: classes.inputBorder }}
            >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
            </TextField>
        );
    }
};
