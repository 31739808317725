const settings = ApiSettings();

//TODO: Cache
async function ApiSettings(): Promise<ApiSettings> {
    const data = await fetch(
        'settings?authToken=2ef0b0a8-6ef5-45e1-81a9-d717c8453680',
    );
    return await data.json();
}

export { settings };

export interface ApiSettings {
    authority: string;
    clientId: string;
    redirect: string;
    apiClientId: string;
    apiBaseUrl: string;
}
