import { settings } from './APISettings';
import { Service } from '../../services/Service';
import Swal from 'sweetalert2';
import downloadjs from 'downloadjs';
import { downloadBlob } from 'components/helpcomponents/blob';

const apiSettings = await settings;
const baseUri = apiSettings.apiBaseUrl;

export default class APIFetcher extends Service {
    async getUsersInApplication() {
        const response = await this.client.get(
            baseUri + 'User/GetAllApplicationUsers',
        );

        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getAllUsersInCompany() {
        const response = await this.client.get(
            baseUri + 'User/GetAllCompanyUsers',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async addUserToRole(obj) {
        const response = await this.client.post(
            baseUri + 'User/AddUserToRole',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async removeUserFromRole(obj) {
        const response = await this.client.post(
            baseUri + 'User/RemoveUserFromRole',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async removeUserFromApplication(obj) {
        const response = await this.client.post(
            baseUri + 'User/RemoveUserFromApplication',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getUsersGroups(obj) {
        const response = await this.client.post(
            baseUri + 'Group/GetUsersGroups',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getAllApplicationGroups() {
        const response = await this.client.get(
            baseUri + 'Group/GetApplicationGroups',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getUsersInGroup(obj) {
        const response = await this.client.post(
            baseUri + 'Group/GetUsersInGroup',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getApplicationBusinessAreas() {
        const response = await this.client.get(
            baseUri + 'BusinessArea/GetApplicationBusinessAreas',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getUsersBusinessAreas(obj) {
        const response = await this.client.post(
            baseUri + 'BusinessArea/GetUsersBusinessAreas',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getUsersInBusinessArea(obj) {
        const response = await this.client.post(
            baseUri + 'BusinessArea/GetUsersInBusinessArea',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async addUserToBusinessArea(obj) {
        const response = await this.client.post(
            baseUri + 'BusinessArea/AddUserToBusinessArea',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            return '';
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
        return '';
    }

    async removeUserFromBusinessArea(obj) {
        const response = await this.client.post(
            baseUri + 'BusinessArea/RemoveUserFromBusinessArea',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            return '';
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
        return '';
    }

    async getLoggedInUsersGroups() {
        const response = await this.client.get(
            baseUri + 'Me/GetLoggedInUsersGroups',
        );

        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else if (response.status === 500) {
            return 500;
        }
    }

    async postFile(data) {
        const response = await this.client.post(
            baseUri + 'Laundry/PostFile',
            data,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            },
        );

        if (
            response.status >= 200 &&
            response.status <= 299 &&
            response.status !== 204
        ) {
            const data = response.data;
            return data;
        } else {
            return '500';
        }
    }

    async getUserImportStatus(data) {
        const response = await this.client.post(
            baseUri + 'Laundry/GetUserImportStatus',
            JSON.stringify(data),
        );
        if (response.status === 204) {
            return '204';
        } else if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else {
            return '500';
        }
    }

    async postEmails(market, emails) {
        const response = await this.client.post(
            baseUri + 'Laundry/PostEmails?market=' + market,
            JSON.stringify(emails),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else {
            return '500';
        }
    }

    async listImportStatus(obj) {
        const response = await this.client.post(
            baseUri + 'Laundry/ListImportStatus',
            JSON.stringify(obj),
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            if (data.Success === false) {
                alert('Unexpected error..');
                return 'Not found';
            }
            return data;
        } else if (response.status === 400) {
            return '400';
        } else if (response.status === 500) {
            return '500';
        }
    }

    async getLaundryUserByCompanyId(obj) {
        const response = await this.client.post(
            baseUri + 'Laundry/PostCompanyForUsers',
            JSON.stringify(obj),
        );
        if (
            response.status >= 200 &&
            response.status <= 299 &&
            response.status !== 204
        ) {
            const data = response.data;
            return data;
        } else if (response.status === 204) {
            return '204';
        } else {
            return '500';
        }
    }

    async getAdminContactInfo() {
        const response = await this.client.get(
            baseUri + 'ContactInfo/GetAdminContactInfo',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getSalesContactInfo() {
        const response = await this.client.get(
            baseUri + 'ContactInfo/GetSalesContactInfo',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getExportContactInfo() {
        const response = await this.client.get(
            baseUri + 'ContactInfo/GetExportContactInfo',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getLaundryContactInfo() {
        const response = await this.client.get(
            baseUri + 'ContactInfo/GetLaundryContactInfo',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getContactData() {
        const response = await this.client.get(
            baseUri + 'ContactInfo/GetContactInfo',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async getProfilePictures() {
        const response = await this.client.get(
            baseUri + 'ContactInfo/GetProfilePic',
            { responseType: 'blob' },
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;
            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            return 500;
        }
    }

    async getReportsList() {
        const response = await this.client.get(
            baseUri + 'Laundry/GetReportsList',
        );
        if (response.status >= 200 && response.status <= 299) {
            const data = response.data;

            return data;
        } else if (response.status === 403) {
            alert('You do not have permission to view this page');
        } else {
            alert('Something went wrong..');
        }
    }

    async downloadFile(file, uri) {
        await this.client
            .post(
                baseUri + uri,
                uri === 'Laundry/CreateStormImportfile'
                    ? file
                    : JSON.stringify(file),
                {
                    headers:
                        uri === 'Laundry/CreateStormImportfile'
                            ? { 'Content-Type': 'multipart/form-data' }
                            : {},
                    timeout: 90000,
                },
            )
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    const regex = /\[(.*?)\]/;
                    const contentDisposition = (response.headers as any)?.get(
                        'content-disposition',
                    );
                    const fileName =
                        uri === 'BMECat/GetBMECatFile'
                            ? file.CustomerNo
                            : decodeURI(
                                  (regex.exec(contentDisposition) as any)[1],
                              ); // gets the file name from content-disposition
                    return response.data.then((body) => {
                        if (
                            uri === 'BMECat/GetBMECatFile' &&
                            body.size < 9999
                        ) {
                            //If bodysize < 9999 - Which it is if BMECat is corrupt.
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                showCloseButton: true,
                                text: 'No file exported. Check inputfields in form and try again..',
                            });
                        } else if (uri === 'BMECat/GetBMECatFile') {
                            downloadjs(
                                body,
                                fileName + '.xml',
                                'application/octet-stream',
                            );
                        } else {
                            uri === 'Laundry/CreateStormImportfile'
                                ? downloadjs(body, fileName, 'text/csv')
                                : downloadjs(
                                      body,
                                      fileName,
                                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                  ); // downloads the file returned.
                        }
                    });
                } else if (response.status === 403) {
                    alert('You do not have permission to view this page'); // error msg if you somehow accsesed the api without having permission to laundry.
                    window.location.reload();
                } else if (response.status === 300) {
                    // msg if no file is returned.
                    uri === 'Laundry/CreateStormImportfile'
                        ? Swal.fire({
                              icon: 'error',
                              title: 'Wrong properties in excelfile.',
                              showCloseButton: true,
                              text: 'Wrong properties in excelfile. Please edit and try again',
                              footer: '<p>Check the instructions for more information</p>',
                          })
                        : Swal.fire({
                              icon: 'info',
                              title: 'Empty result',
                              showCloseButton: true,
                              text: 'No file could be found on these parameters!',
                          });
                } else if (
                    response.status === 502 &&
                    uri === 'Laundry/CreateStormImportfile'
                ) {
                    // general error msg.
                    Swal.fire({
                        icon: 'error',
                        title: 'oops...',
                        showCloseButton: true,
                        text: 'Wrong properties in excelfile. Please edit and try again',
                        footer: '<p>Check the instructions for more information</p>',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        showCloseButton: true,
                        text: 'Something went wrong, contact an administrator',
                    });
                }
            });
    }

    async downloadStormImportFile(file, uri) {
        await this.client
            .post(baseUri + uri, file, {
                headers: { 'Content-Type': 'multipart/form-data' },
                timeout: 90000,
            })
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    const regex = /\[(.*?)\]/;
                    const contentDisposition = (response.headers as any)?.get(
                        'content-disposition',
                    );
                    const fileName = decodeURI(
                        (regex.exec(contentDisposition) as any)[1],
                    ); // gets the file name from content-disposition
                    return downloadjs(response.data, fileName, 'text/csv');
                } else if (response.status === 403) {
                    alert('You do not have permission to view this page'); // error msg if you somehow accsesed the api without having permission to laundry.
                    window.location.reload();
                } else if (response.status === 300) {
                    // msg if no file is returned.
                    Swal.fire({
                        icon: 'error',
                        title: 'Wrong properties in excelfile.',
                        showCloseButton: true,
                        text: 'Wrong properties in excelfile. Please edit and try again',
                        footer: '<p>Check the instructions for more information</p>',
                    });
                } else if (response.status === 502) {
                    // general error msg.
                    Swal.fire({
                        icon: 'error',
                        title: 'oops...',
                        showCloseButton: true,
                        text: 'Wrong properties in excelfile. Please edit and try again',
                        footer: '<p>Check the instructions for more information</p>',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        showCloseButton: true,
                        text: 'Something went wrong, contact an administrator',
                    });
                }
            });
    }

    async downloadLaundryReport(obj) {
        await this.client
            .post('Laundry/DownloadLaundryReport', JSON.stringify(obj), {
                timeout: 90000,
                responseType: 'blob',
            })
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    downloadBlob(response.data, response.headers);
                } else if (response.status === 403) {
                    alert('You do not have permission to view this page'); // error msg if you somehow accsesed the api without having permission to laundry.
                    window.location.reload();
                } else if (response.status === 300) {
                    // msg if no file is returned.
                    Swal.fire({
                        icon: 'info',
                        title: 'Empty result',
                        showCloseButton: true,
                        text: 'No file could be found on these parameters!',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        showCloseButton: true,
                        text: 'Something went wrong, contact an administrator',
                    });
                }
            });
    }
}
