import React, { Component } from 'react';
import ReactGA from 'react-ga';
import menuItems from '../Items.json';
import SideNav from '../navigation/SideNav';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import GetAppIcon from '@mui/icons-material/GetApp';
import InputAdornment from '@mui/material/InputAdornment';
import APIFetcher from '../remoteData/APIFetcher';
import { BlkCircularProgress } from '../helpcomponents/Progress/CircularProgress';
import { withMsal } from '@azure/msal-react';

import Description from '../helpcomponents/Descriptions/Description';
import Swal from 'sweetalert2';

const useStyles = (theme) => ({
    textArea: {
        position: 'absolute',
        top: '50%',
        left: '40%',
        textAlign: 'center',
        minWidth: '40%',
        minHeight: '30%',
        resize: 'both',
        height: '150px',
    },
    fileContentDiv: {
        maxWidth: '70%',
        paddingTop: '10%',
        margin: 'auto',
    },
    descriptionsDiv: {
        flexGrow: 1,
    },
    goBtn: {
        color: 'white',
        marginTop: '3%',
        marginBottom: '3%',
        backgroundColor: '#0d1523',
        '&:hover': {
            backgroundColor: '#FFE000',
            color: 'black',
        },
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 650,
        backgroundColor: '#E0E0E0',
        color: 'black',
        borderRaduius: '25px',
    },
    responsePaper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 768,
        backgroundColor: 'whitesmoke',
        color: 'black',
        borderStyle: 'solid',
    },
    textField: {
        '&$focused $notchedOutline': {
            borderColor: 'black',
            color: 'black',
        },
    },
    focused: { color: 'black' },
    notchedOutline: {},
});

class ImportFromCompanyComponent extends Component {
    static displayName = ImportFromCompanyComponent.name.toUpperCase();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            drawerOpen: true,
            textArea: '',
            responseData: {},
            CompanyId: '',
            dataPosted: false,
            descriptionAndHelpHandler: {
                name: 'ImportFromCompany',
                func: this.setGetHelp,
                helpDiv: true,
            },
        };
        this.api = new APIFetcher(props.msalContext.instance);
    }

    initReactGA = () => {
        ReactGA.initialize('G-X9SBSGYK3H');
        ReactGA.pageview('/laundry/importfromcompany');
    };

    componentDidMount() {
        this.initReactGA();
    }

    shiftSideNav = (changeStatus) => {
        this.setState({ drawerOpen: changeStatus });
    };

    displayResponse = () => {
        const href =
            'https://adminstage.storm.io/admin/customer/company/' +
            this.state.CompanyId +
            '/detail'; //admin.storm.io for prod. adminstage.storm.io for dev and uat.
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.responsePaper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid
                                item
                                xs
                                container
                                direction="column"
                                spacing={2}
                            >
                                <Grid item xs>
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                    >
                                        {this.state.responseData.IsLaundry ? (
                                            <p></p>
                                        ) : (
                                            <p>
                                                This company does not have its
                                                Laundry flag set in storm.
                                                Please set it using the link
                                                below
                                            </p>
                                        )}
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                    >
                                        <a
                                            href={href}
                                            rel="noopener noreferrer nofollow"
                                            target="_blank"
                                        >
                                            Storm
                                        </a>
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="subtitle1"
                                    >
                                        {
                                            this.state.responseData
                                                .CustomersAddedToQueue
                                        }{' '}
                                        users added!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    };

    async triggerDownload(obj) {
        const response = await this.api.getLaundryUserByCompanyId(obj);
        this.setState({ loading: false });
        if (
            response !== '500' &&
            response !== 500 &&
            response !== null &&
            response !== '204'
        ) {
            this.setState({ responseData: response, dataPosted: true });
        } else if (response === '204') {
            this.setState({ dataPosted: false, loading: false });
            Swal.fire({
                icon: 'info',
                showCloseButton: true,
                title: 'NOT FOUND',
                text: 'Company with that Id does not exist',
            });
        } else {
            this.setState({ dataPosted: false, loading: false });
        }
    }

    getFile = (e) => {
        e.preventDefault();
        this.setState({ loading: true, dataPosted: false });
        const data = new FormData(e.target);
        const obj = { CompanyId: data.get('CompanyId') };
        this.triggerDownload(obj);
    };

    displayInputField = () => {
        const { classes } = this.props;
        return (
            <form autoComplete="off" onSubmit={this.getFile}>
                <TextField
                    onChange={this.changeState}
                    required
                    label="StormId"
                    type="text"
                    name="CompanyId"
                    style={{ margin: '10px', minWidth: '20%' }}
                    value={this.state.CompanyId}
                    //className={classes.root}
                    variant="outlined"
                    //color="secondary"
                    InputProps={{
                        classes: {
                            root: classes.textField,
                            focused: classes.focused,
                            notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                Id:
                            </InputAdornment>
                        ),
                    }}
                />
                <br />
                <div style={{ textAlign: 'right', marginRight: '40%' }}>
                    <Button
                        className={classes.goBtn}
                        type="submit"
                        endIcon={<GetAppIcon />}
                    >
                        GO
                    </Button>
                </div>
            </form>
        );
    };

    changeState = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const InputField = this.displayInputField;
        const Response = this.displayResponse;
        const contentStyle = {
            textAlign: 'center',
            transition: 'margin-left 1000ms cubic-bezier(0.23, 1, 0.32, 1)',
        };
        const responseStyle = { justifyContent: 'center', marginTop: '3%' };
        const textAreaStyle = { textAlign: 'center', marginTop: '3%' };

        const storedGroups = JSON.parse(localStorage.getItem('groups'));

        if (storedGroups[3].isMember) {
            // [0] == Admin, [1] == Export, [2] == Import, [3] == Laundry //
            if (this.state.drawerOpen) {
                contentStyle.marginLeft = 256;
            }
            return (
                <div id="parentDiv" style={{ zIndex: '97' }}>
                    <SideNav
                        items={menuItems.Laundry}
                        callback={this.shiftSideNav}
                    />
                    <div id="contentDiv" style={contentStyle}>
                        <div id="instructionDiv" style={responseStyle}>
                            <Description
                                parentCallBack={
                                    this.state.descriptionAndHelpHandler
                                }
                            />
                        </div>
                        <div style={textAreaStyle} id="textAreaDiv">
                            <InputField />
                        </div>
                        <div
                            id="progressDiv"
                            style={{ marginTop: '3%', marginBottom: '3%' }}
                            hidden={!this.state.loading}
                        >
                            <BlkCircularProgress />
                        </div>
                        <div id="response" hidden={!this.state.dataPosted}>
                            <Response />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h1>
                        YOU DO NOT HAVE ACCESS TO VIEW THE LAUNDRY PAGE. PLEASE
                        CONTACT YOUR ADMINISTRATOR TO GAIN ACCESS
                    </h1>
                </div>
            );
        }
    }
}

export default withMsal(withStyles(useStyles)(ImportFromCompanyComponent));
